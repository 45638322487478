@import "../../../variables.scss";

#summary-view {
  .width-limiter {
    padding: 30px 45px;
    max-width: 1300px;
  }

  .summary-heading-container {
    padding-bottom: 0;
  }

  .summary-filter-container {
    padding-top: 0;
  }

  .mobile-only {
    display: none;
    @include media-breakpoint-down(sm) { display: block; }
  }

  .button {
    background: #ffffff;
    padding: 0.2em;
    width: 1.4em;
    height: 1.4em;
    border-radius: 2px;
    cursor: pointer;
    margin: 1em;
    line-height: 1px;
  }

  &.map-view-active { // This view "state" is mobile-only by definition
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      .map-wrapper {
        display: flex;
        flex: 1;
        height: initial;

        .map-selected-container {
          display: block;
          flex: initial;
          position: absolute;
          bottom: 15px;
          margin-left: 15px;
          width: 100%;
        }
      }
      .desktop-filters, .project-heading, .operations,
      .mobile-filters, .imagegrid, .imagetarget-wrapper {
        display: none;
      }
    }
    .viewselector {
      display: none;
    }
  }

  .map-wrapper {
    display: flex;
    height: 400px;
    position: relative;
    @include media-breakpoint-down(sm) { display: none; }

    &.expand {
      height: 700px;
    }

    &.full-screen {
      height: 100vh;
      width: 100vw;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 100;

      .button-container {
        bottom: -1.5rem;
      }

      .map-expand-button {
        display: none;
      }

      .legend-container {
        bottom: -0.5rem
      }
    }

    .button-container {
      bottom: -3rem;
      position: absolute;
      right: 25%;
      z-index: 100;
    }

    .map-expand-button {
      background-color: #fff;
      box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.75);
    }

    .map-full-screen-button {
      background-color: #fff;
      box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.75);
      margin-left: 1rem;
    }

    .map-close-button {
      left: 1px;
      z-index: 1000;
      position: absolute;
      display: none;
      @include media-breakpoint-down(sm) { display: block; }
    }

    .legend-container {
      bottom: -2rem;
      position: absolute;
      z-index: 100;
      left: 1%;
      /* Outline effect without text-stroke */
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;

      .legend-icon {
        background-color: #fff;
        display: inline-block;
        height: calc(1.5em + 1.32rem + 2px);
        width: 3rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        border: 1px solid #ced4da;
        border-radius: 3px;
      }

      .legend-text {
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;
        padding-left: 0.25rem;
      }

      .legend-marker {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
      }

      .legend-row {
        padding-bottom: 0.15rem;
      }

      .form-group {
        display: inline-block;
      }
    }

    .map-container {
      flex: 8;
      padding: 0;
      width: 100%;
    }

    .map-selected-container {
      @include media-breakpoint-down(sm) { display: none; }

      border-left: 3px solid #888;
      flex: 4;
      background-color: #ffffff;
      max-width: 330px;
      .image-close-button {
        position: absolute;
      }
      .imagetarget {
        background-color: #ffffff;
        width: 100%;
        overflow: auto;
        padding: 0;
        margin: 0;
        .image-thumbnail {
          margin-bottom: 0px;
          padding: 0;
          height: 630px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        .image-data {
          table {
            margin-top: 5px;
            width: 100%;
            td {
              padding: 1px 8px;
              font-size: 1.1rem;
            }
          }
          .mapview-counter{
            border-radius: 100%;
            position: absolute;
            background: #cc1844;
            color: white;
            padding: 0;
            right: 5px;
            margin-top: 3px;
            width: 2em;
            height: 2em;
            line-height: 2em;
            text-align: center;
            font-size: 1.1em;
            font-weight: 900;
          }
          .mapview-link {
            color: #cc1844;
          }
        }
      }
    }
  }

  .project-heading {
    padding: 0;
    margin: 10px auto;
    display: flex;
    @include media-breakpoint-down(sm) { margin: 10px 25px; padding: 0; display:block; }
    .project-heading-title {
      flex: 6;
      span { margin-left: 12px; }
      h4 {
        margin: 0;
        overflow: hidden;
        display: inline;
      }
    }
  }

  .viewselector {
    margin: 18px 0px 10px 0px;
    display: flex;
    justify-content: space-between;

    div {
      cursor: pointer;
    }
  }

  .mobile-filters {
    margin: 10px 25px;
    display: none;
    @include media-breakpoint-down(sm) { display: block; }

    .foldable {
      border: 1px solid #ccc;
      margin-bottom: 2px;

      .foldable-title {
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid #ccc;
      }

      &:first-of-type {
        border: 1px solid #ccc;
      }

      .foldable-icon-closed { padding-right: 5px; display: none; }
      .foldable-icon-open {padding-right: 5px; display: inline; }
      &.closed {
        .foldable-icon-closed { display: inline; }
        .foldable-icon-open { display: none; }

        .filterset {
          display: none;
        }
        .foldable-title {
          border-bottom: 0;
        }
      }

      .filterset {
        padding-top: 10px;

        > span {
          cursor: pointer;
          border-radius: 50px;
          margin-bottom: 10px;
          margin-left: 10px;
          padding: 10px;
          border: 1px solid #ccc;
          white-space: nowrap;
          display: inline-block;

          &.active {
            border: 1px solid #cc1844;
            color: #cc1844;
          }
        }
      }
    }
  }

  .desktop-filters {
    display: flex;
    @include media-breakpoint-down(sm) { display: none; }

    .desktop-filters-content-wrap {
      margin: 0;
      display: flex;

      .btn { color: #fff }

      .datepicker-container {
        position: absolute;
        input {
          margin: 0;
          border-radius: 0;
        }
      }

      .filter-block {
        white-space: nowrap;
        margin: 0 2px;
        z-index: 2;
        button {
          background: white;
          border: 1px solid #ced4da;
          color: #495057;
          margin: 0;
          font-size: 14px;
          height: 100%;
          padding: 0 10px;
        }

        &:nth-child(1){
          margin-left: 0;
        }

      }
      input.filter-block {
        width: 150px;
        min-width: 100px;
        font-size: 14px;
        height: 100%;
      }
      .four { flex: 6; }
      .three { flex: 4; }
      .two { flex: 2; }
      .one { flex: 1; }
    }
  }

  .imagegrid-controls {
      width: 100px;
      background: white;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      margin-bottom: 15px;
      height: 40px;
      border: 1px solid #ced4da;

      button {
        width: 50%;
        background: none;
        border: none;
        border-right: 1px solid #ced4da;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 18px;
          height: 18px;
        }

        &:last-child {
          border: none;
        }

        &.active {
          background: $primary;
        }
      }
  }

  .imagegrid {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    background: #ccc;

    &.imagegrid-default {
      .grid-item {
        margin: 0 auto;
        padding: 10px;
        width: 100%;
        overflow: hidden;
        &.active {
          background-color: lightgreen;
        }
        .image-thumbnail {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: 500px;
        }
      }
    }

    &.imagegrid-small {
      .grid-item {
        margin: 0 auto;
        padding: 10px;
        min-width: 175px;
        max-width: 175px;
        flex: 1;
        overflow: hidden;
        &.active {
          background-color: lightgreen;
        }
        .image-thumbnail {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: 120px;
        }
      }
    }
  }

  .imagetarget-wrapper {
    background-color: #eee;
    margin: 20px auto;

    .imagetarget {
      margin: 25px 0;
      padding: 10px;
      display: flex;
      @include media-breakpoint-down(sm) { display: block; }

      .image-thumbnail {
        @include media-breakpoint-down(sm) {
          height: 250px;
          margin-bottom: 1em;
        }
        flex: 4;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      .image-data {
        flex: 8;
        margin: 0 20px;
        input {
          width: 100%;
        }
      }
    }

    .image-operations {
      cursor: pointer;
      border-top: 1px solid;
      margin: 20px 20px 0px 20px;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
    }
  }

  .operations {
    margin: 20px auto;

    @include media-breakpoint-down(sm) { padding: 10px 25px; margin: 0; }

    button {
      margin: 5px;
    }
  }
}

.modal-open {
  .form-check-input { /* Inside modal checkbox */
    width: 18px !important;
    height: 18px !important;
    border: 1px solid red !important;
  }

  /* Edit image */
  #editCoordsModalMap {
    background-color: #eee;
    width: 100%;
    min-width: 200px;
    height:600px;
  }
}

.editModal .modal-dialog {
  max-width: 80%;
}

#closeFullscreen {
  display: none;
}

#root.mobile-map-fullscreen {
  #sidebar {
    display: none !important;
  }
  #mainview {
    padding-left: 0 !important;
  }

  #topbar {
    display: none;
  }

  .mobilemenu {
    display: none;
  }

  .width-limiter {
    display: none;
  }

  .closeFullscreen {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }

    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
