.summaryviews-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .add-summary-button{
    > *{
      margin: 8px;
    }
  }
}
