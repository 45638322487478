@import "../../../../variables";

.cardgrid{
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-auto-rows: 12rem;
    grid-gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.document-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}
.document-list-item-title{
  display: inline-block;
  margin-top: 4px;
  justify-content: space-between;
}
.document-list-date{
  margin-right: 45px; 
}
.document-list-collapse{
  margin-left: 15px;
}
.document-list-item-id{
  display: block;
  float: left;
  margin-right: 45px;
}
.document-list-item{
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0!important;
    border: 2px solid $appBgColor!important;
    margin: 0.25rem;

}
.trash-button{
    display: block;
    float: right;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:hover{
      color: #dc3545;
  }
}
.angle-button{
  display: block;
  float: right;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover{
    color: #007bff;
  }
}
.time-between{
  display: block;
  text-align: center;
}
.time-container{
  display: block;
  text-align: center;
  //margin-bottom: 5rem;
}
.avainiaPanel{
  position: relative;
  background-color: #dc3545;
}
.dropdown-button{
  display: block;
  right: 115px;
  top: 150px;
  position: absolute;

  strong {
    padding: 6px;
  }

  .dropdown-toggle{
    background: white;
    border-color: #ccc;
  }
  .dropdown-menu {
    display: flex;
    align-items: center;
  }
  .datepicker-input{
    margin:0;
  }
 }
.progressreport-div{
  display: block;
  overflow: hidden;
  padding-right:2rem;
  padding-left:2rem;

}
.report-button{
  display: block;
  float: right;
  border: none;
  width: 175px;

}
.progressreport{
  display: flex;
  text-align: left;
  font-size: 1.75em;
  float: left;

}
.listItem{
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 3px solid $appBgColor;
  margin: 0.25rem;
  background-color: $appBgColor;
  font-size: 0.8rem;

}.listItem-value{
    display: block;
    font-size: 1rem;
   }
.collapse-grid{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
