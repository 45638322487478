@import '../../../variables';

.summary-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: $appBgColor;
}
.summary-card-title{
  font-size: 0.9rem;
  text-transform: uppercase;
}
.summary-card-content {
  font-size: 2.25rem;
  font-weight: 600;
}
.summary-card-footer {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-top: 10px;
}
