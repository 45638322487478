@import "../../../variables";

.Sidebar {
  @include boxShadow(lightRight);
  background-color: $white;
  display: flex;
  height: 100vh;
  position: fixed;
  transition: $Sidebar-speed transform;
  user-select: none;
  width: $Sidebar-width;
  will-change: transform;
  z-index: 100;

  .App.is-sidebarActive & {
    transform: translateX(-($Sidebar-width - ($spacer * 2)));
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

  .Sidebar-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    width: 100%;
  }

  .Sidebar-toggleBtn {
    left: $Sidebar-width - ($spacer * 0.75);
    position: fixed;
    top: $spacer;
    z-index: 9999;

    .App.is-sidebarActive & {
      > .Icon {
        transform: rotate(-180deg);
      }
    }
  }

  .Sidebar-user {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: $spacer * 2;
    justify-content: center;
    width: 100%;
  }

    .Sidebar-user-pic {
      align-items: center;
      background: $primary;
      border-radius: 50%;
      display: flex;
      font-size: 40px;
      height: 96px;
      justify-content: center;
      position: relative;
      width: 96px;

      img {
        height: 96px;
        border-radius: 50%;
        width: 96px;
        object-fit: cover;
      }

      &:hover {
        cursor: pointer;
      }
    }

      .Sidebar-user-pic-messageBtn {
        position: absolute;
        left: $spacer * -0.6;
        top: $spacer * -0.6;
      }

    .Sidebar-user-name {
      margin-top: $spacer / 1.5;
      @include textLinkPlain();
    }

    .Sidebar-user-companies {
      font-size: $fontSizeXxs;
      color: $gray-600;
    }

    .Sidebar-user-links {
      line-height: 1.25;

      > a {
        @include textLink($color: $gray-600);
        font-size: $fontSizeXxs;

        &:not(:first-child) {
          margin-left: $spacer / 4;
        }

      }
    }

  .Sidebar-nav {
    margin-bottom: auto;

    > a {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: ($spacer / 2.5) $spacer;

      svg {
        font-size: $Icon-fontSize;
        margin-right: $spacer / 1.5;
      }

      &:hover, &.active {
        background-color: $appBgColorLight;
      }
    }
  }

  .Sidebar-footer {
    padding: $spacer;

    button {
      margin: 0;
    }
  }

.sidebar-summaryviews{
  margin-top: 2rem;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color:$appBgColorLight;
  &:hover, &.active {
    background-color: $white !important;
  }


}
.sidebar-summaryviewlist{
  font-size: 0.9rem;
  background-color: $appBgColorLight;
  &:hover, &.active {
    background-color: $white !important;
  }
}
